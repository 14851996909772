export const API_URL = process.env.REACT_APP_API_URL;

export const roles = {
	ALL: ['SUPER_ADMIN', 'ADMIN', 'USER', 'OPERATION', 'MRF_OPERATION', 'DRIVER'],
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	USER: 'USER',
	OPERATION: 'OPERATION',
	MRF_OPERATION: 'MRF_OPERATION',
	DRIVER: 'DRIVER',
};

export const service = {
	ALL: ['MRF', 'COLLECTION', 'RECYCLING'],
	MRF: 'MRF',
	COLLECTION: 'COLLECTION',
	RECYCLING: 'RECYCLING',
};

export const defaultPagination = { sortBy: '', page: 0, pageSize: 10 };

export const customReports = [
	{
		title: 'Daily Vehicle Running Report',
		fileName: 'daily_vehicle_running_report',
		api: 'customVehicleRunningReport',
		dateKey: ['routeDate'],
		avoidKeys: [],
		avoidSubKeys: [],
		arrayKey: 'customers',
		hasJson: false,
		isGrouped: true,
	},
	{
		title: 'Monthly Completed Collection Report',
		fileName: 'monthly_completed_collection_report',
		api: 'customMonthlyCompletedCollectionReport',
		dateKey: ['completedOn'],
		avoidKeys: [],
		avoidSubKeys: [],
		arrayKey: 'collectionDates',
		hasJson: true,
		isGrouped: true,
	},
	{
		title: 'Monthly Pending Site Visit Report',
		fileName: 'monthly_pending_site_visit_report',
		api: 'customMonthlyPendingSiteVisitReport',
		dateKey: ['collectionOn'],
		avoidKeys: [],
		avoidSubKeys: ['customerId'],
		arrayKey: 'customers',
		hasJson: false,
		isGrouped: true,
	},
	{
		title: 'Vehicle Performance Report',
		fileName: 'vehicle_perfomance_report',
		api: 'customMonthlyVehiclePerfomanceReport',
		dateKey: [],
		avoidKeys: [],
		avoidSubKeys: [],
		arrayKey: null,
		hasJson: false,
		isGrouped: false,
	},
];

export const endpoints = {
	DASHBOARD_USER_DATA: `${API_URL}/dashboard/operation`,

	DASHBOARD_USER_MRF_DATA: `${API_URL}/dashboard/mrfoperation`,

	GMAP_GEOCODING_ENDPOINT:
		'https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&address=',

	AUTHENTICATE: `${API_URL}/authenticate`,
	ADDRESS_DATA: `${API_URL}/master/addressdata`,

	MASTER_ITEM_DATA: `${API_URL}/master/items`,
	ALL_MASTER_TYPES: `${API_URL}/master/alltypes`,
	ALL_MASTER_ITEMS: `${API_URL}/master/dataTypeItems`,
	MODIFY_MASTER_TYPE: `${API_URL}/master/masterType`,
	MODIFY_MASTER_ITEM: `${API_URL}/master/masterItem`,
	PAGED_MASTER_ITEMS: `${API_URL}/master/pagedItems`,
	GET_MASTER_TYPE_BY_KEY: `${API_URL}/master/masterTypeByKey`,

	ALL_WASTE_CATEGORIES: `${API_URL}/category/pagedWasteCategory`,
	ALL_WASTE_SUB_CATEGORIES: `${API_URL}/category/wasteSubCategory`,
	MODIFY_WASTE_CATEGORY: `${API_URL}/category/wasteCategory`,

	ALL_APP_CONFIGS: `${API_URL}/config/paged`,
	MODIFY_APP_CONFIGS: `${API_URL}/config/appConfig`,

	ALL_DEVICES: `${API_URL}/device/paged`,
	MODIFY_DEVICES: `${API_URL}/device/modify`,

	GET_CUSTOMERS: `${API_URL}/customer/fetch`,
	GET_FULL_CUSTOMERS: `${API_URL}/customer/fetchAll`,
	GET_CUSTOMER: `${API_URL}/customer/fetch`,
	MODIFY_CUSTOMERS: `${API_URL}/customer/modify`,
	GET_CUSTOMER_QR_CODES: `${API_URL}/customer/customerQRCodeDetails`,
	GET_CUSTOMER_HISTORY: `${API_URL}/collectionOperation/customerCollectionHistory`,

	GET_DRIVERS: `${API_URL}/driver/fetch`,
	GET_DRIVER: `${API_URL}/driver/fetch`,
	GET_ALL_DRIVERS: `${API_URL}/driver/fetchAll`,
	MODIFY_DRIVER: `${API_URL}/driver/modify`,

	GET_VEHICLES: `${API_URL}/vehicle/fetch`,
	GET_VEHICLE: `${API_URL}/vehicle/fetch`,
	MODIFY_VEHICLE: `${API_URL}/vehicle/modify`,
	GET_VEHICLE_USERS: `${API_URL}/vehicle/vehicleUsers`,
	GET_ALL_VEHICLES: `${API_URL}/vehicle/fetchAll`,

	GET_INFRA_POINTS: `${API_URL}/infrapoint/fetch`,
	GET_INFRA_POINT: `${API_URL}/infrapoint/fetch`,
	MODIFY_INFRA_POINT: `${API_URL}/infrapoint/modify`,
	GET_ALL_INFRA_POINT: `${API_URL}/infrapoint/fetchAll`,

	GET_INFRA_USER_MAPPING: `${API_URL}/infrapoint/fetchInfraUsers`,
	CREATE_INFRA_USER_MAPPING: `${API_URL}/infrapoint/mapUser`,
	MODIFY_INFRA_USER_MAPPING: `${API_URL}/infrapoint/modifyUserMapping`,
	DROP_INFRA_USER_MAPPING: `${API_URL}/infrapoint/removeMapping`,

	GET_MERCHANTS: `${API_URL}/merchant/fetch`,
	GET_MERCHANT: `${API_URL}/merchant/fetch`,
	MODIFY_MERCHANT: `${API_URL}/merchant/modify`,

	GET_MERCHANT_CATEGORY_MAPPING: `${API_URL}/merchant/fetchCategoryMapping`,
	CREATE_MERCHANT_CATEGORY_MAPPING: `${API_URL}/merchant/mapCategory`,
	MODIFY_MERCHANT_CATEGORY_MAPPING: `${API_URL}/merchant/modifyCategoryMapping`,
	DROP_MERCHANT_CATEGORY_MAPPING: `${API_URL}/merchant/removeCategoryMapping`,

	GET_MERCHANT_VEHICLE_MAPPING: `${API_URL}/merchant/fetchVehicleMapping`,
	CREATE_MERCHANT_VEHICLE_MAPPING: `${API_URL}/merchant/mapVehicle`,
	MODIFY_MERCHANT_VEHICLE_MAPPING: `${API_URL}/merchant/modifyVehicleMapping`,
	DROP_MERCHANT_VEHICLE_MAPPING: `${API_URL}/merchant/removeVehicleMapping`,

	GET_ACTIVE_ROLES: `${API_URL}/userRoles/activeRoles`,
	GET_ROLES: `${API_URL}/userRoles/roles`,
	GET_ROLE: `${API_URL}/userRoles/fetchRole`,
	MODIFY_ROLE: `${API_URL}/userRoles/role`,
	GET_USER_ROLES: `${API_URL}/userRoles/fetch`,
	UPDATE_USER_ROLE: `${API_URL}/userRoles/update`,

	GET_USERS: `${API_URL}/user/primary`,
	GET_USER: `${API_URL}/user/fetch`,
	MODIFY_USER: `${API_URL}/user/modify`,
	GET_USER_PROFILE_WITH_ROLE: `${API_URL}/user/fetchUserProfileWithRole`,
	GET_USER_WITH_ROLE: `${API_URL}/user/fetchUsersWithRole`,

	ALL_MASTER_ROUTES: `${API_URL}/master/route/page`,
	GET_MASTER_ROUTE: `${API_URL}/master/route`,
	MASTER_ROUTE_FORM_DATA: `${API_URL}/master/route/details`,
	MODIFY_MASTER_ROUTE: `${API_URL}/master/route/modify`,
	GET_MASTER_ROUTE_LOCATIONS: `${API_URL}/master/route/locations`,

	COLLECTION_ROUTES_BY_DATE: `${API_URL}/route/fetchByDate`,
	COLLECTION_ALL_ROUTES_BY_DATE: `${API_URL}/route/fetchAllByDate`,
	ROUTE_CREATION_DATA: `${API_URL}/route/details`,
	MODIFY_ROUTE: `${API_URL}/route/modify`,
	GET_ROUTE: `${API_URL}/route/fetch`,
	CLONE_ROUTES: `${API_URL}/route/clone`,
	GET_ROUTE_LOCATIONS: `${API_URL}/route/locations`,
	GET_VEHICLE_ROUTES: `${API_URL}/route/vehicleRoute`,

	SUBMIT_COLECTION_DETAILS: `${API_URL}/route/colectionDetails` /* DEPRECATED */,
	REJECT_COLECTION: `${API_URL}/route/rejectColection` /* DEPRECATED */,

	GET_TENANTS: `${API_URL}/tenancy/fetch`,
	GET_TENANT: `${API_URL}/tenancy/fetch`,
	MODIFY_TENANT: `${API_URL}/tenancy/modify`,

	GET_GPS_SERVICES: `${API_URL}/gpsService/fetch`,
	GET_GPS_SERVICE: `${API_URL}/gpsService/fetch`,
	GET_ALL_GPS_SERVICE: `${API_URL}/gpsService/fetchAll`,
	MODIFY_GPS_SERVICE: `${API_URL}/gpsService/modify`,

	GET_APPROVAL_REQUESTS: `${API_URL}/collectionOperation/fetchApprovalRequests`,
	MODIFY_APPROVAL_REQUEST: `${API_URL}/collectionOperation/approveCancellationRequest`,

	ACTIVITY_MONITOR_LIST: `${API_URL}/collectionActivity/fetchOverallCollectionByDate`,
	ACTIVITY_MONITOR_DETAIL: `${API_URL}/collectionActivity/fullActivity`,
	GPS_STATUS: `${API_URL}/collectionActivity/getVehicleGPSLocation`,
	ROUTE_HISTORY: `${API_URL}/v2/driverapp/fetchLocationHistory`,

	MRF_POINT_DETAILS: `${API_URL}/mrf/treatment/getAssignedMrfPointDetails`,
	MRF_CATEGORYSPLIT_DETAILS: `${API_URL}/mrf/treatment/getCategorySplitDetails`,
	MRF_CATEGORYSPLITBY_MRF_DETAILS: `${API_URL}/mrf/treatment/getCategorySplitByMRF`,
	MRF_GET_ASSIGNED_TREATMENTS: `${API_URL}/mrf/treatment/getAssignedTreatments`,
	MRF_MODIFY_TREATMENT: `${API_URL}/mrf/treatment/modify`,

	MRF_GET_USER_INFRA: `${API_URL}/mrf/treatment/fetchUserInfra`,
	MRF_GET_INWARD_STATUS: `${API_URL}/mrf/treatment/getInwardStatus`,
	MRF_GET_OUTWARD_STATUS: `${API_URL}/mrf/treatment/getOutwardStatus`,
	MRF_GET_TREATMENT_LIST: `${API_URL}/mrf/treatment/getAllTreatmentDetails`,
	MRF_GET_TREATMENT_DATA: `${API_URL}/mrf/treatment/getTreatmentData`,
	MRF_ASSIGN_TREATMENT: `${API_URL}/mrf/treatment/assignTreatment`,
	MRF_ASSIGNMENT_UPDATE: `${API_URL}/mrf/treatment/updateAssignedTreatment`,

	// IOT DEMO EENDPOINTS
	PROXIMITY_SENSOR: `${API_URL}/iot/getProximitySensor`,
	LEVEL_SENSOR: `${API_URL}/iot/getLevelSensor`,
	WEIGHT_SENSOR: `${API_URL}/iot/getLoadSensor`,

	// REPORTS
	COLLECTION_HISTORY_REPORT: `${API_URL}/collection/reports`,
	MRF_HISTORY_REPORT: `${API_URL}/mrf/reports`,

	// CONFIG
	ALL_CONFIGS: `${API_URL}/config/all`,
};
